import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'
import { AxiosError } from 'axios'

import { QUERY_KEYS } from '@api'

import { ReviewItemsApi } from './api'

const useReviewItems = () => {
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery([QUERY_KEYS.REVIEW_ITEMS], () => ReviewItemsApi.getReviewItems())

  const { t } = useTranslation()

  const onError = (error: AxiosError) => {
    notification.error({
      message: error?.message,
    })
  }

  const onValidateReviewItemSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.REVIEW_ITEMS)
    notification.success({
      message: t('reviewitems.notifications.validated_success'),
    })
  }

  const onRejectReviewItemSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.REVIEW_ITEMS)
    notification.success({
      message: t('reviewitems.notifications.reject_success'),
    })
  }

  const { mutate: validateReviewItem, isLoading: isLoadingValidate } = useMutation(
    [QUERY_KEYS.REVIEW_ITEMS_VALIDATE],
    ({ id, collectibleCount }: { id: number; collectibleCount: number }) =>
      ReviewItemsApi.validateReviewItem(id, collectibleCount),
    {
      onSuccess: onValidateReviewItemSuccess,
      onError,
    },
  )

  const { mutate: rejectReviewItem, isLoading: isLoadingReject } = useMutation(
    [QUERY_KEYS.REVIEW_ITEMS_REJECT],
    ReviewItemsApi.rejectReviewItem,
    {
      onSuccess: onRejectReviewItemSuccess,
      onError,
    },
  )

  //console.log('data', data)

  return {
    reviewItems: data,
    isLoading,
    validateReviewItem,
    isLoadingValidate,
    rejectReviewItem,
    isLoadingReject,
  }
}

export { useReviewItems }
