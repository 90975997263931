import { useState } from "react"
import { useQuery } from "react-query"

import { QUERY_KEYS } from "@api"

import { ChallengesApi } from "./api"

export const useChallenges = () => {
    const [currentPage, setCurrentPage] = useState<number>(0)

    // const { t } = useTranslation()
    // const queryClient = useQueryClient()


    const moveToPage = (page: number) => {
      setCurrentPage(page)
    }


    const { data: challenges, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.CHALLENGES, currentPage],
        queryFn: () => ChallengesApi.getChallenges(currentPage),
        keepPreviousData: true,
        staleTime: 5000,
      })

      return {
        challenges,
        isLoading,
        moveToPage
      }
    
}

