import { api } from "@api"

import { ChallengeResponse } from "../types"


const getChallenges = async (page?: number) => {
    const response = await api.get<ChallengeResponse>(`/challenges${page ? `?page=${page}` : ''}`)
  
    return response?.data
}

export const ChallengesApi = {
    getChallenges
}

