import { Button, Modal, Spin } from 'antd'
import { useTranslation } from 'react-i18next'

import { AdminUser } from '@types'

import { StyledDeleteMessage, StyledRow } from '..'
import { useUsers } from '../../hooks'

interface DeleteUserModalProps {
  visible: boolean
  onClose: () => void
  userId?: number
  user?: AdminUser
}

const DeleteUserModal = ({ visible, onClose, userId, user }: DeleteUserModalProps) => {
  const { t } = useTranslation()
  const { deleteUser, isDeleting } = useUsers()
  
return (
    <Modal
      open={visible}
      title={t('users.placeholders.delete_user')}
      onCancel={onClose}
      transitionName="ant-fade"
      focusTriggerAfterClose={false}
      maskClosable={false}
      closable={false}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {t('game_tables.edit.button.cancel')}
        </Button>,
        <Button
          danger
          type="primary"
          key="delete"
          onClick={() => {
            deleteUser(userId)
            if (!isDeleting) {
              onClose()
            }
          }}
        >
          {t('users.buttons.delete')}
        </Button>,
      ]}
    >
      <Spin spinning={isDeleting}>
        <StyledRow>
          <StyledDeleteMessage>{`${t('users.placeholders.delete_confirmation')}${user?.fullName}${t(
            'users.placeholders.delete_confirmation_end',
          )}`}</StyledDeleteMessage>
        </StyledRow>
      </Spin>
    </Modal>
  )
}

export { DeleteUserModal }
