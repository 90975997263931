import { Button, Input, Modal, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AdminUserById } from '@types'

import { StyledRow } from '..'
import { useUsers } from '../../hooks'

interface EditUserModalProps {
  visible: boolean
  onClose: () => void
  userById?: AdminUserById
}
interface FormState {
  userId: number | undefined
  firstName: string | undefined
  lastName: string | undefined
}

const EditUserModal = ({ visible, onClose, userById }: EditUserModalProps) => {
  const { t } = useTranslation()
  const { updateUser, isLoadingUpdate } = useUsers()
  const [form, updateForm] = useState<FormState>({
    userId: userById?.id,
    firstName: userById?.firstName,
    lastName: userById?.lastName,
  })
  useEffect(() => {
    updateForm({
      userId: userById?.id,
      firstName: userById?.firstName,
      lastName: userById?.lastName,
    })
  }, [userById])

  return (
    <Modal
      open={visible}
      title={t('users.placeholders.edit_user')}
      onCancel={onClose}
      maskClosable={false}
      transitionName="ant-fade"
      focusTriggerAfterClose={false}
      closable={false}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {t('game_tables.edit.button.cancel')}
        </Button>,
        <Button
          type="primary"
          key="update"
          onClick={() => {
            updateUser(form)
            onClose()
          }}
          disabled={isLoadingUpdate || !form.firstName || !form.lastName}
        >
          {t('game_tables.edit.button.save')}
        </Button>,
      ]}
    >
      <Spin spinning={isLoadingUpdate}>
        <StyledRow>
          <h3>{t('users.placeholders.first_name')}:</h3>
          <Input
            placeholder={t('users.placeholders.first_name')}
            value={form.firstName}
            onChange={e => updateForm({ ...form, firstName: e.currentTarget.value })}
          />
        </StyledRow>
        <StyledRow>
          <h3>{t('users.placeholders.last_name')}:</h3>
          <Input
            placeholder={t('users.placeholders.last_name')}
            value={form.lastName}
            onChange={e => updateForm({ ...form, lastName: e.currentTarget.value })}
          />
        </StyledRow>
        <StyledRow>
          <h3>{t('users.placeholders.username')}:</h3>
          <Input
            placeholder={t('users.placeholders.username')}
            value={userById?.username}
            disabled
          />
        </StyledRow>
      </Spin>
    </Modal>
  )
}

export { EditUserModal }
