import { Button, Checkbox, DatePicker, Input,  Modal, notification, Spin, Upload, UploadProps } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { UploadOutlined } from '@ant-design/icons'


import { StyledRow } from '..'
import { useQuizzes } from '../../hooks'
import { QuizzesApi } from '../../hooks/api'

interface CreateQuizzModalProps {
  visible: boolean
  onClose: () => void
}
interface FormState {
  name: string | undefined
  description: string | undefined
  imageUrl: string | undefined,
  startDate?: Date ,
  endDate?: Date , 
  imageFile?: File, 
  enabled: boolean
}

const CreateQuizzModal = ({ visible, onClose }: CreateQuizzModalProps) => {
  const { t } = useTranslation()
  const { createQuizz, isCreating } = useQuizzes()
  const [fileList, setFileList] = useState<any>([])

  const [form, updateForm] = useState<FormState>({
    name: '',
    description: '',
    imageUrl: '',
    startDate: undefined,
    endDate: undefined, 
    enabled: true
  })

  useEffect(() => {
    updateForm({
      name: '',
      description: '', 
      imageUrl: '', 
      startDate: undefined, 
      endDate: undefined, 
      enabled: true
    })
    
    setFileList([])

  }, [visible])

  const uploadProps: UploadProps = {
    name: 'file',
    accept: '.png, .jpg, .jpeg',
    listType: "picture-card",
    defaultFileList: [],
    fileList: fileList,
    beforeUpload: ()=>false,
    multiple: false,
    maxCount: 1,
    onChange(info) {

      console.log('info', info)
      if (info.fileList.length > 0) {
        setFileList([info.fileList[0]])
        updateForm({ ...form, imageFile: info.fileList[0].originFileObj as File })
      } else {
        setFileList([])
        updateForm({ ...form, imageFile: undefined })
      }

    },
  };

  const handleSubmit = () => {


    if(form.startDate === undefined || form.endDate === undefined) {
      notification.error({
        message: t('quizzes.notifications.error_date')
      })

      return
    }

    if(form.startDate >= form.endDate) {
      notification.error({
        message: t('quizzes.notifications.wrong_dates')}
      )

      return
    }

    if(form.name === '' || form.description === '') {
      notification.error({
        message: t('quizzes.notifications.empty_fields')
      })

      return

    }

    if(form.imageFile) {

      QuizzesApi.uploadQuizzImage(form.imageFile)
        .then((imageUrl) => {

          createQuizz({
            ...form,
            startDate: form.startDate as Date,
            endDate: form.endDate as Date,
            imageUrl})
          if (!isCreating) onClose()

        })
        .catch((error) => {
          notification.error(
            {
              message:error.message
            })
        })
    }
    else {
      notification.error({message: t('quizzes.notifications.error_image_upload')})
    }


  }

  return (
    <Modal
      open={visible}
      title={t('quizzes.placeholders.new_quizz')}
      onCancel={onClose}
      maskClosable={false}
      closable={false}
      transitionName="ant-fade"
      focusTriggerAfterClose={false}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {t('game_tables.edit.button.cancel')}
        </Button>,
        <Button
          type="primary"
          key="update"
          onClick={handleSubmit}
          disabled={
            isCreating 
          }
        >
          {t('game_tables.edit.button.save')}
        </Button>,
      ]}
    >
      <Spin spinning={isCreating}>
        <StyledRow>
          <h3>{t('quizzes.placeholders.name')}:</h3>
          <Input
            required
            autoComplete="false"
            placeholder={t('quizzes.placeholders.name')}
            value={form.name}
            onChange={e => updateForm({ ...form, name: e.currentTarget.value })}
          />
        </StyledRow>
        <StyledRow>
          <h3>{t('quizzes.placeholders.description')}:</h3>
          <TextArea
            required
            autoComplete="false"
            placeholder={t('quizzes.placeholders.description')}
            value={form.description}
            onChange={e => updateForm({ ...form, description: e.currentTarget.value })}
          />
        </StyledRow>
        <StyledRow>
          <h3>{t('quizzes.placeholders.image')}:</h3>
          
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined rev={''} />}></Button>
          </Upload>

          {/* <h3>{t('quizzes.placeholders.imageUrl')}:</h3>
          <Input
            required
            autoComplete="false"
            type="email"
            placeholder={t('quizzes.placeholders.imageUrl')}
            value={form.imageUrl}
            onChange={e => updateForm({ ...form, imageUrl: e.currentTarget.value })}
          /> */}
        </StyledRow>
        <StyledRow>
          <h3>{t('quizzes.placeholders.startDate')}:</h3>
          <DatePicker
            format="YYYY-MM-DD HH:mm"
            showTime={true}
            showSecond={false}
            minuteStep={5}
            style={{ width: '100%' }} 
            autoComplete="false"
            placeholder={t('quizzes.placeholders.startDate')}

            // defaultValue={moment(form.startDate) ?? moment(Date.now())}
            value={(form.startDate ? moment(form.startDate) : undefined)}
            onChange={(date) => updateForm({ ...form, startDate: date?.toDate() ?? new Date()})}
          />
        </StyledRow>
        <StyledRow>
          <h3>{t('quizzes.placeholders.endDate')}:</h3>
          <DatePicker
            format="YYYY-MM-DD HH:mm"
            showTime={true}
            showSecond={false}
            minuteStep={5}
            style={{ width: '100%' }} 
            autoComplete="false"
            placeholder={t('quizzes.placeholders.endDate')}

            //defaultValue={moment(form.endDate) ?? moment(Date.now())}
            value={form.endDate ? moment(form.endDate) : undefined}
            onChange={(date) => updateForm({ ...form, endDate: date?.toDate()?? new Date()})}
          />
        </StyledRow>
        <StyledRow>
          <h3>{t('quizzes.placeholders.enabled')}:</h3>
          <Checkbox
            style={{ marginLeft: '10px' }}
            checked={form.enabled}
            onChange={e => updateForm({ ...form, enabled: e.target.checked })}  
          />
        </StyledRow>
      </Spin>
    </Modal>
  )
}

export { CreateQuizzModal }
