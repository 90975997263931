import { Button, Modal } from "antd"
import { useTranslation } from "react-i18next"

import { useQuizzes } from "../../hooks"
import { Answer } from "../../types"
import { StyledDeleteMessage, StyledRow } from ".."

interface DeleteAnswerModalProps {
    answer: Answer
    onClose: () => void
    visible: boolean, 
    questionId: number
}

const DeleteAnswerModal = ({ answer, onClose, visible, questionId }: DeleteAnswerModalProps) => {
    const { t } = useTranslation()
    const { deleteAnswer } = useQuizzes()

    const handleDelete = () => {

        if(!answer) {
            return
        }

        console.log('answer', answer)
        deleteAnswer({answerId: answer.id!, questionId})
        onClose()
    }
    
    return (
        <Modal
        title={t('questions.placeholders.delete_answer')}
        open={visible}
        onCancel={onClose}
        footer={[
            <Button key="cancel" onClick={onClose}>
              {t('game_tables.edit.button.cancel')}
            </Button>,
            <Button
              danger
              type="primary"
              key="delete"
              onClick={handleDelete}
            >
              {t('questions.buttons.delete')}
            </Button>,
          ]}
        >
            <StyledRow>
              <StyledDeleteMessage>{`${t('questions.placeholders.delete_answer_confirmation')} "${answer?.text}" ${t(
                'questions.placeholders.delete_answer_confirmation_end',
              )}`}</StyledDeleteMessage>
            </StyledRow>
        </Modal>
    )
}

export {DeleteAnswerModal}