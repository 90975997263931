import { useTranslation } from "react-i18next"
import { Button, Col, Row, Table, Tooltip } from "antd"
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"

import { Challenge } from "./types"
import { StyledAlignedTitleRow, StyledTitle } from "./components"
import { useChallenges } from "./hooks"


export const Challenges = () => {

    const { t } = useTranslation()

    const {challenges, isLoading, moveToPage} = useChallenges()

    const columns = [
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
  
        {
          title: t('common.action'),
          key: 'action',
          render: (item: Challenge) => (
            <>
                <Row>
                    <Col style={{ marginRight: '5px' }}>
                        <Tooltip title={t('quizzes.buttons.tooltips.edit')}>
                            <Button
                            type='primary'
                            onClick={() => {
                            console.log('edit',item.id)
                            }}
                            icon={<EditOutlined rev='' />}
                            />
                        </Tooltip>
                    </Col>
                    <Col style={{ marginRight: '5px' }}>
                        <Tooltip title={t('quizzes.buttons.tooltips.delete')}>
                            <Button
                            type='primary'
                            danger
                            onClick={() => {
                                console.log('delete',item.id)
                            }}
                            icon={<DeleteOutlined rev='' />}
                            />
                        </Tooltip>
                    </Col>
                </Row>
                </>
          ),
        },
      ]


  return (
    <Row>
    <Col span={24} style={{ marginBottom: '2%' }}>
      <Col span={24}>
        <StyledAlignedTitleRow>
          <Col style={{ marginRight: '1%' }}>
            <StyledTitle>{t('menu.challenges')}</StyledTitle>
          </Col>
          <Col style={{ marginBottom: '1%' }}>
              <Tooltip title={t('challenges.buttons.tooltips.new_challenge')}>
                <Button type='primary' onClick={()=>{}}>
                  <PlusOutlined rev='' />
                  {t('challenges.buttons.new_challenge')}
                </Button>
              </Tooltip>
            </Col>
        </StyledAlignedTitleRow>
      </Col>
      <Col span={24}>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={challenges?.data}
          rowKey='id'
          pagination={{
            
            position: ['bottomCenter'],
            total: (challenges?.total || 0),
            current: (challenges?.page || 0)+1,
            onChange: (page) => {
              moveToPage(page-1)
            }
          }}
        />
      </Col>
    </Col>
    {/* <EditQuizzModal visible={editQuizzModalOpen} onClose={onCloseEditModal} quizById={quizzById} isLoading={isLoadingQuizzById} />
    {quizzById && <QuestionsQuizzModal 
                    allowEdit={new Date(quizzById.startDate) >= (new Date())}
                    visible={questionsQuizzModalOpen} 
                    onClose={onCloseQuestionsQuizzModal} 
                    quizzId={quizzById?.id} 
                    isLoading={isLoadingQuizzById} />}

    <CreateQuizzModal visible={createModalOpen} onClose={onCloseCreateModal} />
    <DeleteQuizzModal
        visible={deleteQuizzModal}
        onClose={onCloseDeleteClick}
        quizzId={deleteQuizzId}
        quizz={deleteQuizz}
      />*/}
  </Row> 
  )
}

