import React from 'react'
import { Card, Col, Descriptions, Row, Spin, Image } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { useTranslation } from 'react-i18next'

import { Metadata } from '../types'

interface ItemMetadataProps {
  metadata: Metadata | undefined
  isLoading: boolean
}

const ItemMetadata = ({ metadata, isLoading }: ItemMetadataProps) => {
  const { t } = useTranslation()

  if (!metadata) {
    return <div></div>
  }

  return (
    <Spin spinning={isLoading}>
      <Row justify={'center'} gutter={24}>
        {metadata.mediaFiles.frontImage && (
          <Col>
            <Card hoverable style={{ width: 150 }} cover={<Image src={metadata.mediaFiles.frontImage} alt='front' />}>
              <Meta title='Front' />
            </Card>
          </Col>
        )}
        {metadata.mediaFiles.backImage && (
          <Col>
            <Card hoverable style={{ width: 150 }} cover={<Image src={metadata.mediaFiles.backImage} alt='back' />}>
              <Meta title='Back' />
            </Card>
          </Col>
        )}
        {metadata.mediaFiles.rightArmImage && (
          <Col>
            <Card
              hoverable
              style={{ width: 150 }}
              cover={<Image src={metadata.mediaFiles.rightArmImage} alt='right' />}
            >
              <Meta title='Right' />
            </Card>
          </Col>
        )}
        {metadata.mediaFiles.leftArmImage && (
          <Col>
            <Card hoverable style={{ width: 150 }} cover={<Image src={metadata.mediaFiles.leftArmImage} alt='left' />}>
              <Meta title='Left' />
            </Card>
          </Col>
        )}
        {metadata.mediaFiles.signatureImage && (
          <Col>
            <Card
              hoverable
              style={{ width: 150 }}
              cover={<Image src={metadata.mediaFiles.signatureImage} alt='signature' />}
            >
              <Meta title='Signature' />
            </Card>
          </Col>
        )}
      </Row>
      <Row style={{ marginTop: '20px' }} justify={'center'} gutter={24}>
        <Descriptions title={t('reviewitems.titles.data_item')} bordered column={2}>
          <Descriptions.Item label='Name'>{metadata.name}</Descriptions.Item>
          <Descriptions.Item label='Type'>{metadata.type}</Descriptions.Item>
          <Descriptions.Item label='Year'>{metadata.year}</Descriptions.Item>
          <Descriptions.Item label='Season'>{metadata.season}</Descriptions.Item>
          <Descriptions.Item label='Dorsal'>{metadata.dorsal}</Descriptions.Item>
          <Descriptions.Item label='Equipment Type'>{metadata.equipmentType}</Descriptions.Item>
          <Descriptions.Item label='Signer'>{metadata.signer}</Descriptions.Item>
          <Descriptions.Item label='Brand'>{metadata.brand.name}</Descriptions.Item>
          <Descriptions.Item label='History'>{metadata.history}</Descriptions.Item>
        </Descriptions>
      </Row>
    </Spin>
  )
}

export default ItemMetadata
