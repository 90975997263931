import { useTranslation } from "react-i18next"
import { Button, Modal, Spin } from "antd"

import { useQuizzes } from "../../hooks"
import { StyledDeleteMessage, StyledRow } from ".."
import { Question } from "../../types"

interface DeleteQuestionModalProps {
    visible: boolean
    onClose: () => void
    questionId?: number
    question?: Question
  }
  
  const DeleteQuestionModal = ({ visible, onClose, questionId, question }: DeleteQuestionModalProps) => {
    const { t } = useTranslation();
  
    const { deleteQuestion, isDeleting } = useQuizzes()


    return (
        <Modal
          open={visible}
          title={t('Questiones.placeholders.delete_Question')}
          onCancel={onClose}
          transitionName="ant-fade"
          focusTriggerAfterClose={false}
          maskClosable={false}
          closable={false}
          footer={[
            <Button key="cancel" onClick={onClose}>
              {t('game_tables.edit.button.cancel')}
            </Button>,
            <Button
              danger
              type="primary"
              key="delete"
              onClick={() => {
                deleteQuestion(questionId)
                if (!isDeleting) {
                  onClose()
                }
              }}
            >
              {t('users.buttons.delete')}
            </Button>,
          ]}
        >
          <Spin spinning={isDeleting}>
            <StyledRow>
              <StyledDeleteMessage>{`${t('questions.placeholders.delete_confirmation')}${question?.text}${t(
                'users.placeholders.delete_confirmation_end',
              )}`}</StyledDeleteMessage>
            </StyledRow>
          </Spin>
        </Modal>
      )

}

export { DeleteQuestionModal }
