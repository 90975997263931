import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ConfigProvider } from 'antd'
import esES from 'antd/es/locale/es_ES'

import { UserProvider } from '@contexts'
import { PrivateRoute, AppLayout } from '@components'
import { theme, GlobalStyles } from '@styles'

import { Login } from './Login'
import { Users } from './Users'
import { ResetPassword } from './PublicWebs/UpdatePassword'
import { VerifyUser } from './PublicWebs/ValidateEmail'
import { Contracts } from './Contracts'
import ReviewItem from './ReviewItem'
import Quizzes from './Quizzes'
import {Challenges} from './Challenges'
import { CollectiblesCreate, CollectiblesMain } from './Collectibles'

const queryClient = new QueryClient()
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider locale={esES}>
            <div>
              <UserProvider>
                <Routes>
                  <Route path='/login' element={<Login />} />
                  <Route path='/login/change-password' element={<ResetPassword />} />
                  <Route path='/register/confirm-email' element={<VerifyUser />} />
                  <Route
                    path='/users'
                    element={
                      <PrivateRoute>
                        <AppLayout body={<Users />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/contracts'
                    element={
                      <PrivateRoute>
                        <AppLayout body={<Contracts />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/review-items'
                    element={
                      <PrivateRoute>
                        <AppLayout body={<ReviewItem />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/quizzes'
                    element={
                      <PrivateRoute>
                        <AppLayout body={<Quizzes />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/collectibles'
                    element={
                      <PrivateRoute>
                        <AppLayout body={<CollectiblesMain />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/collectibles/new'
                    element={
                      <PrivateRoute>
                        <AppLayout body={<CollectiblesCreate />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/challenges'
                    element={
                      <PrivateRoute>
                        <AppLayout body={<Challenges />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/'
                    element={
                      <PrivateRoute>
                        <AppLayout body={<Users />} />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </UserProvider>
            </div>
          </ConfigProvider>
        </QueryClientProvider>
      </Router>
    </ThemeProvider>
  )
}

export { App }
